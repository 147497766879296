// Function to load a script with a callback when it's loaded
function loadScript(src, id, callback) {
  var script = document.createElement('script');
  script.src = src;
  script.id = id;
  script.onload = callback;
  document.head.appendChild(script);
}

// Check if the script is already loaded
function isScriptLoaded(id) {
  return !!document.getElementById(id);
}

// Load the Equally AI script if it's not already loaded
if (process.env.NODE_ENV !== 'development' && !isScriptLoaded('equallyWidget')) {
  loadScript("https://widget.equally.ai/equally-widget.min.js", 'equallyWidget', function() {
    // Set the API key and initialize Equally AI when the script is loaded
    if (!window.EQUALLY_AI_API_KEY) {
      //ToDo: Add key in credentials
      window.EQUALLY_AI_API_KEY = "wlvsj9ip6q9v4yk1yqx1wschpcosw5pf";
      var intervalId = setInterval(function() {
        if (window.EquallyAi) {
          clearInterval(intervalId);
          window.EquallyAi = new EquallyAi();
        }
      }, 500);
    }
  });
}